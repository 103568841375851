import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet } from 'react-router-dom';
import { Add, Edit, LockOutlined, Search } from '@mui/icons-material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  istokenState,
  menuTitleState,
  openViewDialogState,
  filterDataState,
} from '../../state/atoms/atom';
import { Breadcrumbs, TextField } from '@mui/material';
import SearchBox from '../../components/SearchBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ScrapDialog from '../../components/ScrapDialog';
import ViewDataDialog from '../../components/ViewDataDialog';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export default function LayoutScreen() {
  const navigate = useNavigate();
  const setData = useSetRecoilState(filterDataState);
  const setIsAuthenticated = useSetRecoilState(istokenState);
  const [menuTitle, setMenuTitle] = useRecoilState(menuTitleState);
  const isSmallScreen = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(!isSmallScreen);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openViewDialog, setOpenViewDialog] = useRecoilState(openViewDialogState);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (isSmallScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmallScreen]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ScrapDialog open={openDialog} onClose={() => setOpenDialog(false)} />
      <ViewDataDialog
        open={openViewDialog}
        onClose={() =>{
          setData([]);
          setOpenViewDialog(false);
        }}
      />

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='absolute' open={open}>
          <Toolbar
            sx={{
              pr: '24px',
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <div style={{ flex: 1 }}></div>
            <IconButton
              color='inherit'
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('menu');
                setIsAuthenticated(false);
                navigate('/');
              }}
            >
              <LockOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant='permanent' open={open}>
          <Toolbar
            variant='regular'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              color='inherit'
              fontWeight='700'
              noWrap
              sx={{ flexGrow: 1 }}
            >
              WE KOREAN
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component='nav'>
            <React.Fragment>
              <ListItemButton
                onClick={(e) => {
                  setMenuTitle(e.target.textContent);
                  navigate(`/`);
                }}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
              </ListItemButton>
              <ListItemButton
                onClick={(e) => {
                  setMenuTitle(e.target.textContent);
                  navigate(`/`);
                }}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary='Words' />
              </ListItemButton>
              <ListItemButton
                onClick={(e) => {
                  setMenuTitle(e.target.textContent);
                  navigate(`/`);
                }}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary='Conversations' />
              </ListItemButton>
              <ListItemButton
                onClick={(e) => {
                  setMenuTitle(e.target.textContent);
                  navigate(`/`);
                }}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary='Grammars' />
              </ListItemButton>
            </React.Fragment>
            <Divider sx={{ my: 1 }} />
            <React.Fragment>
              <ListSubheader component='div' inset>
                Setting
              </ListSubheader>
              <ListItemButton
                onClick={(e) => {
                  setMenuTitle(e.target.textContent);
                  navigate('/user-profile');
                }}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary='User Setting' />
              </ListItemButton>
            </React.Fragment>
          </List>
        </Drawer>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <div style={{ padding: 20 }}>
            <Fab
              onClick={() => setOpenDialog(true)}
              color='primary'
              aria-label='add'
              style={{ position: 'absolute', bottom: 40, right: 20 }}
            >
              <AddIcon />
            </Fab>
            <div style={{ paddingBottom: 20 }}>
              <Outlet />
            </div>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
