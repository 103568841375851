import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import { filterDataState } from '../state/atoms/atom';
import { useRecoilValue } from 'recoil';

function ListItemComponent(props) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}>
      {props.data?.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <div>
            <ListItem disablePadding>
              <ListItemButton role={undefined} dense>
                <ListItemText
                  id={labelId}
                  primary={value.korean}
                  secondary={value.english}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        );
      })}
    </List>
  );
}

export default ListItemComponent;
