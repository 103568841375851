import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function CardStyle1(props) {
  return (
    <Card>
      <CardActionArea style={{ height: '100%' }} onClick={props.onClick}>
        <CardContent style={{ height: '100%' }}>
          <Typography gutterBottom variant='h5' component='div'>
            {props.title}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {props.subTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
