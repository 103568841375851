import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthBanner from '../../components/AuthBanner';
import WeKoreanCopyRight from '../../components/WeKoreanCopyRight';
import { ApiService } from '../../services/ApiService';
import LoadingBackdrop from '../../components/LoadingBackdrop';

const defaultTheme = createTheme();

export default function RegisterScreen() {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    confirm_password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);
    try {
      const response = await ApiService.register(null, formData);
      if (response.success) {
        alert(response.message);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.errors);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <LoadingBackdrop open={loading} />
        <CssBaseline />
        <AuthBanner />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <LockOutlinedIcon style={{ marginBottom: 10 }} />
              <Typography component='h1' variant='h5'>
                Register
              </Typography>
            </div>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ width: '100%' }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                value={formData.email}
                onChange={handleChange}
                error={errors?.email ? true : false}
                helperText={
                  errors?.email &&
                  errors?.email.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='name'
                label='User Name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                error={errors?.name ? true : false}
                helperText={
                  errors?.name &&
                  errors?.name.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                value={formData.password}
                onChange={handleChange}
                error={errors?.password ? true : false}
                helperText={
                  errors?.password &&
                  errors?.password.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='confirm_password'
                label='Confirm Password'
                type='password'
                id='confirm_password'
                value={formData.confirm_password}
                onChange={handleChange}
                error={errors?.confirm_password ? true : false}
                helperText={
                  errors?.confirm_password &&
                  errors?.confirm_password.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to='/forgot-password' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to='/login' variant='body2'>
                    {'Already have an account? Login'}
                  </Link>
                </Grid>
              </Grid>
              <WeKoreanCopyRight sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
