import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { List, AutoSizer } from 'react-virtualized';

function ListComponent(props) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height+360}
          width={width}
          rowCount={props.data.length}
          rowHeight={48}
          rowRenderer={({ key, index, style }) => (
            <div key={key} style={style}>
              <ListItem button key={index}>
                {props.selectedKeys.map((key) => (
                  <ListItemText
                    style={{ flex: 1 }}
                    key={key}
                    primary={props.data[index][key]}
                  />
                ))}
              </ListItem>
              <Divider />
            </div>
          )}
        />
      )}
    </AutoSizer>
  );
}

export default ListComponent;
