import * as React from 'react';
import TitleCard from '../../components/CardStyle1';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from '../../services/ApiService';
import Divider from '@mui/material/Divider';
import ListComponent from '../../components/ListComponent2';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { useNavigate } from 'react-router-dom';
import ListItemComponent from '../../components/ListItemComponent';
import { useSetRecoilState, useRecoilStat, useRecoilState } from 'recoil';
import { filterDataState, openViewDialogState } from '../../state/atoms/atom';
const HomeScreen = () => {
  const [openViewDialog, setOpenViewDialog] =
    useRecoilState(openViewDialogState);
  const setIsFilterData = useSetRecoilState(filterDataState);
  const navigator = useNavigate();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [id, setId] = React.useState('');
  const [word,setWord] = React.useState([]);

  const handleOnload = async () => {
    setData([]);
    setLoading(true);
    try {
      const response = await ApiService.list('', '');
      setData(response.data);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    setLoading(true);
    try {
      const response = await ApiService.deleteAll('', '');
      if (response.success) {
        setData([]);
      }
      alert(response.message);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  React.useEffect(function () {
    handleOnload();
  }, []);

  React.useEffect(function () {
    const filteredWord = data.filter(item => item.common_type === '650ceaf8c04233ff3c753cb1');
    setWord(filteredWord.data);
    console.log(filteredWord.data);
  }, [data]);

  return (
    <>
      <LoadingBackdrop open={loading} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        {word ? word.data?.length : 0} Most Common Korean
        <span></span>
        <div>
          <Button
            size='small'
            variant='outlined'
            color='error'
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAll}
          >
            Delete ALL
          </Button>
        </div>
      </div>
      <Divider />
      <div className='displayGride' style={{ marginTop: 20 }}>
        {word?.data?.map((item, key) => (
          <TitleCard
            key={key}
            onClick={() => {
              setIsFilterData(item.body);
              console.log(item.body);
              setOpenViewDialog(true);
            }}
            title={`${item.body?.length} ${item?.title}`}
            subTitle={item?.title}
          />
        ))}
      </div>
    </>
  );
};
export default HomeScreen;
