import { atom } from 'recoil';

export const istokenState = atom({
  key: 'istokenState',
  default: localStorage.getItem('token'),
});

export const scrapDataState = atom({
  key: 'scrapDataState',
  default: [],
});

export const filterDataState = atom({
  key: 'filterDataState',
  default: [],
});

export const dataState = atom({
  key: 'dataState',
  default: [],
});

export const filterByIdDataState = atom({
  key: 'filterByIdDataState',
  default: [],
});

export const currentPageState = atom({
  key: 'currentPageState',
  default: 1,
});

export const menuTitleState = atom({
  key: 'menuTitleState',
  default: 'Dashboard',
});

export const openViewDialogState = atom({
  key: 'openViewDialogState',
  default: false,
});

export const closeState = atom({
  key: 'closeState',
  default: false,
});
