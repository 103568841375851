import { Link, Typography } from '@mui/material';

export default function WeKoreanCopyRight(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://wekorean.penhsokra.com/'>
        We Korean
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
