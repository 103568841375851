import * as React from 'react';
import TitleCard from '../../components/CardStyle1';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from '../../services/ApiService';
import Divider from '@mui/material/Divider';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { filterDataState, openViewDialogState, dataState, menuTitleState } from '../../state/atoms/atom';
const DashboardScreen = () => {
  const setOpenViewDialog = useSetRecoilState(openViewDialogState);
  const [data, setData] = useRecoilState(dataState);
  const [filteredData, setFilteredData] = React.useState([]);
  const menu = useRecoilValue(menuTitleState);
  const setIsFilterData = useSetRecoilState(filterDataState);



  const [loading, setLoading] = React.useState(false);
  const handleOnload = async () => {
    setData([]);
    setLoading(true);
    try {
      const response = await ApiService.list('', '');
      setData(response.data);
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    setLoading(true);
    try {
      const response = await ApiService.deleteAll('', '');
      if (response.success) {
        setData([]);
      }
      alert(response.message);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  React.useEffect(function () {
    handleOnload();
  }, []);

  React.useEffect(function () {
    if (menu !== "Dashboard") {
      setFilteredData(data?.filter(item => item.common_type_name === menu));
    } else {
      setFilteredData(data);
    }
    console.log(filteredData);
  }, [menu,data]);

  return (
    <>
      <LoadingBackdrop open={loading} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        {menu}
        <span></span>
        <div>
          <Button
            size='small'
            variant='outlined'
            color='error'
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAll}
          >
            Delete ALL
          </Button>
        </div>
      </div>
      <Divider />
      <div className='displayGride' style={{ marginTop: 20 }}>
        {filteredData?.map((item, key) =>
          item?.data.map((innerItem, index) => (
            <TitleCard
              key={index}
              onClick={() => {
                setIsFilterData(innerItem.body);
                console.log(innerItem.body);
                setOpenViewDialog(true);
              }}
              title={`${innerItem?.body?.length} ${innerItem?.title}`}
              subTitle={item.common_type_name}
            />
          ))
        )}
      </div>
    </>
  );
};
export default DashboardScreen;
