import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
export default function AuthBanner() {
  return (
    <Grid
      item
      xs={false}
      sm={12}
      md={7}
      sx={{
        background: '#1b0b3b',
      }}
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography
          component='h1'
          variant='h2'
          style={{ fontWeight: 600, color: '#fff' }}
        >
          We <span style={{ color: '#b094ff' }}>KOREAN</span>
        </Typography>
        <Typography
          component='h1'
          variant='h5'
          style={{ color: '#fff', opacity: 0.8 }}
        >
          The Korean education system is renowned for
          <br />
          its rigor,placing a heavy emphasis on academic achievement.
        </Typography>
      </Box>
    </Grid>
  );
}
