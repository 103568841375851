import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ApiService } from '../services/ApiService';
import { useRecoilValue, useRecoilState } from 'recoil';
import { filterDataState, dataState, closeState } from '../state/atoms/atom';
import { TextareaAutosize } from '@mui/base';
import LoadingBackdrop from './LoadingBackdrop';
import { Divider } from '@mui/material';

const axios = require('axios');
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveDataToDatabaseDialog(props) {
  const data = useRecoilValue(filterDataState);
  const [errors, setErrors] = React.useState({});
  const [resData, setResData] = useRecoilState(dataState);
  const [close, setClose] = useRecoilState(closeState);
  const [modifiedData, setModifiedData] = React.useState(
    data.map((item) => ({
      korean: item.key_0,
      english: item.key_1,
    }))
  );

  const jsonString = JSON.stringify(modifiedData, null, 2);

  const [loading, setLoading] = React.useState(false);
  const [commonTypeId, setCommonTypeId] = React.useState(
    '650ceaf8c04233ff3c753cb1'
  );
  const [commonTypeName, setCommonTypeName] = React.useState('Words');
  const [title, setTitle] = React.useState('');
  const handleSubmit = async () => {
    console.log('My data :' + data);
    setLoading(true);
    setErrors({});
    try {
      const finallData = {
        common_type: commonTypeId,
        common_type_name:commonTypeName,
        title:title,
        body: modifiedData,
      };
      const response = await ApiService.save('', finallData);
      if (response.success) {
        setResData(response.data);
        setClose(false);
        alert(response.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrors(error.errors);
      alert(error.errors);
      setLoading(false);
    }
  };
  React.useEffect(
    function () {
      setModifiedData(
        data.map((item) => ({
          korean: item.key_0,
          english: item.key_1,
          example: '',
        }))
      );
    },
    [data]
  );

  function findLabelName(selectedValue) {
  switch (selectedValue) {
    case '650ceaf8c04233ff3c753cb1':
      return 'Words';
    case '650cea2d24eac4847c12fd00':
      return 'Conversations';
    case '650ced6fc078440499f94952':
      return 'Grammars';
    default:
      return ''; // Handle default case if necessary
  }
}

  return (
    <div>
      <LoadingBackdrop open={loading} />
      <BootstrapDialog
        onClose={props.onClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Save
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={{ width: 420 }}>
            <TextField
              fullWidth
              id='outlined-basic'
              value={title}
              label='Enter Title'
              variant='outlined'
              size='small'
              name='title'
              onChange={(e) => setTitle(e.target.value)}
              error={errors?.title ? true : false}
                helperText={
                  errors?.title &&
                  errors?.title.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
            />
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <FormLabel id='demo-form-control-label-placement'>
                CHOOSE TYPE
              </FormLabel>
              <Divider style={{ marginTop: 5 }} />
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='650ceaf8c04233ff3c753cb1'
                name='radio-buttons-group'
                style={{ flexDirection: 'row' }}
                onChange={(e) => {
                    const selectedValue = e.target.value;
                    const labelName = findLabelName(selectedValue);
                    setCommonTypeName(labelName);
                    setCommonTypeId(selectedValue)
                  }}
              >
                <FormControlLabel
                  value='650ceaf8c04233ff3c753cb1'
                  control={<Radio />}
                  name='Words'
                  label='Words'
                />
                <FormControlLabel
                  value='650cea2d24eac4847c12fd00'
                  control={<Radio />}
                  name='Conversations'
                  label='Conversations'
                />
                <FormControlLabel
                  value='650ced6fc078440499f94952'
                  control={<Radio />}
                  name='Grammars'
                  label='Grammars'
                />
              </RadioGroup>
            </FormControl>
            <div style={{marginTop:20}}></div>
            <FormControl fullWidth>
              <TextField
              id="standard-multiline-flexible"
              label="JSON DATA"
              name='body'
              multiline
              maxRows={12}
              rows={12}
              value={jsonString}
              onChange={(e) => {
                  try {
                    const parsedData = JSON.parse(e.target.value);
                    setModifiedData(parsedData);
                  } catch (error) {
                    setModifiedData([]);
                    console.error('JSON parsing error:', error);
                  }
                }}
                error={errors?.body ? true : false}
                helperText={
                  errors?.body &&
                  errors?.body.map((message, index) => (
                    <React.Fragment key={index}>
                      {message}
                      <br />
                    </React.Fragment>
                  ))
                }
            />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
