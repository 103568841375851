import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Breadcrumbs, Card, Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Send } from '@mui/icons-material';
import ListComponent from './ListComponent';
import LoadingBackdrop from './LoadingBackdrop';
import { ApiService } from '../services/ApiService';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  scrapDataState,
  istokenState,
  currentPageState,
  filterDataState,
  closeState,
} from '../state/atoms/atom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import SaveDataToDatabaseDialog from './SaveDataToDatabaseDialog';
const axios = require('axios');
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function ScrapDialog(props) {
  const navigate = useNavigate();
  const [data, setData] = useRecoilState(scrapDataState);
  const [close, setClose] = useRecoilState(closeState);
  const [filteredData, setFilteredData] = useRecoilState(filterDataState);
  const [token, setIstokenState] = useRecoilState(istokenState);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const allKeys = [...new Set(data.flatMap((item) => Object.keys(item)))];

  const [selectedKeys, setSelectedKeys] = React.useState(allKeys);
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [formData, setFormData] = React.useState({
    url: 'https://www.topikguide.com/6000-most-common-korean-words-1/',
    parentClass: 'table',
    subParentClass: 'tr',
    childClass: 'td',
    // url: '',
    // parentClass: '',
    // subParentClass: '',
    // childClass: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedKeys([...selectedKeys, name]);
    } else {
      setSelectedKeys(selectedKeys.filter((key) => key !== name));
    }
    handleFilteredData(data);
  };

  const handleFilteredData = (data) => {
    const filteredData = data.map((item, index) => {
      const filteredItem = {};
      selectedKeys.forEach((key, keyIndex) => {
        filteredItem[`key_${keyIndex}`] = item[key];
      });
      return filteredItem;
    });
    setFilteredData(filteredData);
    console.log(filteredData);
  };

  const handleSubmit = async () => {
    //e.preventDefault();
    setErrors({});
    setData([]);
    setLoading(true);
    try {
      const response = await ApiService.scrap(token, formData);
      if (response.success) {
        setData(response.data);
        setTotalPage(response.total_page);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.errors);
      alert(error.message);
      if (error.message === 'Invalid authentication token.') {
        localStorage.removeItem('menu');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setIstokenState('');
        navigate('/');
      }
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (event, selectedPage) => {
    setCurrentPage(selectedPage);
    setFormData((prevData) => ({
      ...prevData,
      currentPage: selectedPage,
    }));
    //console.log(selectedPage);
  };

  React.useEffect(() => {
    setSelectedKeys(allKeys);
  }, [data]);

  React.useEffect(() => {
    handleFilteredData(data);
  }, [selectedKeys]);

  React.useEffect(() => {
    if (currentPage !== 0) {
      handleSubmit();
    }
  }, [currentPage]);

  const handleButtonClick = () => {
    setCurrentPage(1);
    setFormData((prevData) => ({
      ...prevData,
      currentPage: 1,
    }));
    setCurrentPage(1);
    handleSubmit();
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={props.onClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              SCRAP DATA
            </Typography>
            <Button autoFocus color='inherit' onClick={() => setClose(true)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <div style={{ padding: 20 }}>
          <Card style={{ padding: 20 }} variant='outlined'>
            <Box component='form' noValidate autoComplete='off'>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <div style={{ padding: 5 }}>
                    <TextField
                      fullWidth
                      size='small'
                      id='outlined-basic'
                      label='Url'
                      value={formData.url}
                      variant='outlined'
                      name='url'
                      onChange={handleChange}
                      error={errors?.url ? true : false}
                      helperText={
                        errors?.url &&
                        errors?.url.map((message, index) => (
                          <React.Fragment key={index}>
                            {message}
                            <br />
                          </React.Fragment>
                        ))
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <div style={{ padding: 5 }}>
                    <TextField
                      fullWidth
                      size='small'
                      id='outlined-basic'
                      label='Parent elements or class'
                      variant='outlined'
                      onChange={handleChange}
                      value={formData.parentClass}
                      name='parentClass'
                      error={errors?.parentClass ? true : false}
                      helperText={
                        errors?.parentClass &&
                        errors?.parentClass.map((message, index) => (
                          <React.Fragment key={index}>
                            {message}
                            <br />
                          </React.Fragment>
                        ))
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <div style={{ padding: 5 }}>
                    <TextField
                      fullWidth
                      size='small'
                      id='outlined-basic'
                      label='Sub parent elements or class'
                      variant='outlined'
                      onChange={handleChange}
                      value={formData.subParentClass}
                      name='subParentClass'
                      error={errors?.subParentClass ? true : false}
                      helperText={
                        errors?.subParentClass &&
                        errors?.subParentClass.map((message, index) => (
                          <React.Fragment key={index}>
                            {message}
                            <br />
                          </React.Fragment>
                        ))
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={2}>
                  <div style={{ padding: 5 }}>
                    <TextField
                      fullWidth
                      size='small'
                      id='outlined-basic'
                      label='Childrent elements or class'
                      variant='outlined'
                      onChange={handleChange}
                      value={formData.childClass}
                      name='childClass'
                      error={errors?.childClass ? true : false}
                      helperText={
                        errors?.childClass &&
                        errors?.childClass.map((message, index) => (
                          <React.Fragment key={index}>
                            {message}
                            <br />
                          </React.Fragment>
                        ))
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                  <div style={{ padding: 5 }}>
                    <Button
                      variant='outlined'
                      size='large'
                      fullWidth
                      onClick={handleButtonClick}
                    >
                      <Send />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <span>
              Result : <strong>{data ? data.length : null}</strong>
            </span>
            <Pagination
              size='small'
              page={currentPage}
              count={totalPage}
              color='primary'
              onChange={handlePageChange}
            />
          </div>
          <Divider />
          {data
            ? data.length !== 0 && (
                <Card
                  variant='outlined'
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 15,
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {allKeys.map((key) => (
                      <label key={key}>
                        <input
                          type='checkbox'
                          name={key}
                          checked={selectedKeys.includes(key)}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        {key}
                      </label>
                    ))}
                  </Box>
                </Card>
              )
            : null}
          {data ? (
            <ListComponent data={data} selectedKeys={selectedKeys} />
          ) : null}
        </div>
      </Dialog>
      <LoadingBackdrop open={loading} />
      <SaveDataToDatabaseDialog open={close} onClose={() => setClose(false)} />
    </div>
  );
}
