export const API_BASE_URL = 'https://we-korean-server.penhsokra.com/';
//export const API_BASE_URL = 'https://wekorean-api.penhsokra.com/';
// API endpoints
export const API_ENDPOINTS = {
  LOGIN: 'api/auth/login',
  REGISTER: 'api/auth/register',
  SCRAP: 'api/scrap',
  SAVE: 'api/common',
  LIST: 'api/common',
  LIST_BY_ID: 'api/common/id',
  DELETE_ALL: 'api/common',
};

// API request methods
export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

// API headers (you can customize this as per your API requirements)
export const API_HEADERS = (token) => ({
  Accept: 'application/json',
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
});
