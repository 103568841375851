import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_METHODS,
  API_HEADERS,
} from '../constants/ApiConstants';

async function callApi(
  endpoint,
  method = API_METHODS.GET,
  token = null,
  body = null
) {
  const url = API_BASE_URL + endpoint;
  const requestOptions = {
    method,
    headers: API_HEADERS(token ? token : null),
    body: body ? JSON.stringify(body) : null,
  };
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error;
  }
}
export const ApiService = {
  login: async (token, body) => {
    const endpoint = API_ENDPOINTS.LOGIN;
    const method = API_METHODS.POST;
    return callApi(endpoint, method, token, body);
  },

  register: async (token, body) => {
    const endpoint = API_ENDPOINTS.REGISTER;
    const method = API_METHODS.POST;
    return callApi(endpoint, method, token, body);
  },

  scrap: async (token, body) => {
    const endpoint = API_ENDPOINTS.SCRAP;
    const method = API_METHODS.POST;
    return callApi(endpoint, method, token, body);
  },

  save: async (token, body) => {
    const endpoint = API_ENDPOINTS.SAVE;
    const method = API_METHODS.POST;
    return callApi(endpoint, method, token, body);
  },
  list: async (token, body) => {
    const endpoint = API_ENDPOINTS.SAVE;
    const method = API_METHODS.GET;
    return callApi(endpoint, method, token, body);
  },
  listById: async (token, body) => {
    const endpoint = API_ENDPOINTS.LIST_BY_ID;
    const method = API_METHODS.POST;
    return callApi(endpoint, method, token, body);
  },
  deleteAll: async (token, body) => {
    const endpoint = API_ENDPOINTS.DELETE_ALL;
    const method = API_METHODS.DELETE;
    return callApi(endpoint, method, token, body);
  },
};
