import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useRecoilValue } from 'recoil';
import { List, AutoSizer } from 'react-virtualized';
import ListItemButton from '@mui/material/ListItemButton';
import {
  filterDataState
} from '../state/atoms/atom';
import SearchBox from './SearchBox';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function ViewDataDialog(props) {
  const data = useRecoilValue(filterDataState);
  const [filter, setFilter] = React.useState('');
  const filteredData = data?.filter(
    (item) =>
      item?.korean?.toLowerCase().includes(filter.toLowerCase()) ||
      item?.english?.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        style={{ overflow: 'hidden' }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <SearchBox
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant='h6'
              component='div'
            ></Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={props.onClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>


        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height - 64}
              rowCount={filteredData.length}
              rowHeight={60}
              rowRenderer={({ key, index, style }) => (
                <div key={key} style={style}>
                  <ListItem disablePadding>
                    <ListItemButton role={undefined} dense>
                      <ListItemText
                        primary={filteredData[index].korean}
                        secondary={filteredData[index].english}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </div>
              )}
            />
          )}
        </AutoSizer>
      </Dialog>
    </div>
  );
}
