import * as React from 'react';
import TitleCard from '../../components/CardStyle1';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from '../../services/ApiService';
import Divider from '@mui/material/Divider';
import ListComponent from '../../components/ListComponent2';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ListItemComponent from '../../components/ListItemComponent';

const FilterScreen = () => {
  const [data, setData] = React.useState([]);

  return (
    <>
      <ListItemComponent />
    </>
  );
};
export default FilterScreen;
