import { useRecoilValue } from 'recoil';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/auth/LoginScreen';
import DashboardScreen from './screens/dashboard/DashboardScreen';
import RegisterScreen from './screens/auth/RegisterScreen';
import ForgotPasswordScreen from './screens/auth/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/auth/ResetPasswordScreen';
import NotFoundScreen from './screens/notfound/NotFoundScreen';
import HomeScreen from './screens/home/HomeScreen';
import UserProfileScreen from './screens/user/UserProfileScreen';
import { istokenState } from '../src/state/atoms/atom';
import LayoutScreen from './screens/layout/LayoutScreen';
import FilterScreen from './screens/filter/FilterScreen';

export default function App() {
  const isAuthenticated = useRecoilValue(istokenState);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<NotFoundScreen />} />
        <Route path='/' element={<LoginScreen />} />
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
        <Route path='/reset-password' element={<ResetPasswordScreen />} />
        {isAuthenticated ? (
          <Route path='/' element={<LayoutScreen />}>
            <Route index element={<DashboardScreen />} />
            <Route path='/:menu' element={<HomeScreen />} />
            <Route path='/detail' element={<FilterScreen />} />
            <Route path='/user-profile' element={<UserProfileScreen />} />
          </Route>
        ) : (
          <Route path='/login' element={<LoginScreen />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}
